import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
// import { auth, db } from "./firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, db } from "../config/firebase";

import { jwtDecode } from "jwt-decode";

export function useUserDetails() {
  const [userDetail, setUserDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Helper function to check if the token is expired
  const isTokenExpired = (token) => {
    try {
      const decodedToken = jwtDecode(token); // Decode the token to check its expiry time
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      return decodedToken.exp < currentTime; // Return true if token is expired
    } catch (error) {
      console.error("Error decoding token:", error);
      return true; // If there's an error decoding the token, assume it's invalid/expired
    }
  };

  // Function to refresh the access token manually (only if needed)
  const refreshAccessToken = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const token = await user.getIdToken(true); // Force refresh the token
        localStorage.setItem("accessToken", token); // Update token in local storage
        setAccessToken(token); // Update token in state
      }
    } catch (err) {
      console.error("Error refreshing token:", err);
      navigate("/login"); // Redirect to login if there's an error
    }
  };

  // Fetch user details and token when component mounts
  useEffect(() => {
    const fetchUserDetail = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          let token = await user.getIdToken(); // Get current token

          // If token is expired, refresh it
          if (isTokenExpired(token)) {
            await refreshAccessToken(); // Force refresh if expired
            token = localStorage.getItem("accessToken");
          }

          setAccessToken(token); // Set the token in state

          // Fetch user details from Firestore
          const docRef = doc(db, "Users", user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setUserDetail(docSnap.data());
          } else {
            setError("User data not found.");
          }
        } else {
          setError("No user is signed in.");
          navigate("/login"); // Redirect to login if no user is signed in
        }
      } catch (err) {
        setError("Failed to fetch user data.");
        console.error("Error fetching user data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetail();
  }, [navigate, location.state]);

  return {
    userDetail,
    accessToken,
    loading,
    error,
    refreshAccessToken,
    setAccessToken,
  };
}
