import Axios from "axios";

// const CHECKOUT_URL =
//   "https://devapi.ez-byte.com:5050/groupadmin/api/v1/product/checkout";

const CHECKOUT_URL = process.env.REACT_APP_CHECKOUT_URL;

export default async function CheckOutService(payload, accessToken) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  try {
    const response = await Axios.post(CHECKOUT_URL, payload, { headers });

    return response.data;
  } catch (error) {
    console.log(`ERROR FROM CHECKOUT SERVICE: `, error);
  }
}
