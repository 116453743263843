import { createSlice } from "@reduxjs/toolkit";
import { act } from "react";

const initialState = {
  name: "",
  email: "",
  photoURL: "",
  accessToken: "",
  roles: [],
  prefLanguage: "",
  prefCountry: "",
  prefTimezone: "",
  isBusiness: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setRoles(state, action) {
      state.roles = [...action.payload];
    },

    setLanguage(state, action) {
      state.prefLanguage = action.payload;
    },

    setCountry(state, action) {
      state.prefCountry = action.payload;
    },

    setTimeZone(state, action) {
      state.prefTimezone = action.payload;
    },

    setIsBusiness(state, action) {
      state.isBusiness = action.payload;
    },
  },
});

export const { setRoles, setLanguage, setCountry, setTimeZone, setIsBusiness } =
  userSlice.actions;
export default userSlice.reducer;

// const initialState = {
//   name: "",
//   email: "",
//   photoURL: "",
//   accessToken: "",
//   roles: [],
//   language: "",
// };

// const userSlice = createSlice({
//   name: "user",
//   initialState,
//   reducers: {
//     setRoles(state, action) {
//       state.roles = [...action.payload];
//     },

//     setLanguage(state, action) {
//       state.language = action.payload;
//     },
//   },
// });

// export const { setRoles, setLanguage } = userSlice.actions;
// export default userSlice.reducer;
