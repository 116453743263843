import "../../newComponent/products/ProductPricing.css";

import logo from "../../assets/ezebyte/Ezebyte-Logo-color.png";

import { useEffect, useState } from "react";
import { useUserDetails } from "../../customHook/userDetails";

import { useNavigate } from "react-router-dom";
import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material";

import CheckOutService from "../../services/checkoutService";
import GetProductService from "../../services/getProductService";
import { signOut } from "firebase/auth";
import { auth } from "../../config/firebase";

export default function ProductPricing() {
  const [personalBillingPeriod, setPersonalBillingPeriod] = useState("Monthly");
  const [businessBillingPeriod, setBusinessBillingPeriod] = useState("Monthly");

  const [allProducts, setAllProducts] = useState(null);

  const [isBackDropOpen, setIsBackDropOpen] = useState(false);
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [iframeURL, setIframURL] = useState("");

  const navigate = useNavigate();

  const { userDetail, accessToken } = useUserDetails();

  console.log(accessToken);

  // Function to get filtered cars based on billing period and type
  const getFilteredCars = (isBusiness, billingPeriod) => {
    const cars = allProducts?.data.filter((car) => car.business === isBusiness);
    return cars?.reduce((acc, car) => {
      const existingCar = acc.find((c) => c.name === car.name);
      if (!existingCar) {
        const matchingCar = cars.find(
          (c) => c.name === car.name && c.billingPeriod === billingPeriod
        );
        if (matchingCar) acc.push(matchingCar);
      }
      return acc;
    }, []);
  };

  const personalPlans = getFilteredCars(false, personalBillingPeriod);
  const businessPlans = getFilteredCars(true, businessBillingPeriod);

  const getProducts = async () => {
    try {
      const productData = await GetProductService(accessToken);
      if (productData) {
        setAllProducts(productData);
      }
    } catch (error) {
      console.log(`Error getting response from the product`, error);
    }
  };

  useEffect(() => {
    if (accessToken) {
      getProducts();
    }
  }, [accessToken]);

  console.log(allProducts);

  const handleSubscribe = async (car) => {
    setIsSnackOpen(false);
    setIsBackDropOpen(true);

    const payload = {
      quantity: 1,
      // name: `${car.name} - ${car.billingPeriod}`,
      billingPeriod: `${car.billingPeriod}`,
      name: `${car.name}`,
    };

    // const payload = {
    //   quantity: 1,
    //   name: "Personal Starter - Monthly",
    // };

    try {
      const getResponse = await CheckOutService(payload, accessToken);

      console.log(`---RESPONSE---`);
      console.log("");
      console.log(getResponse);
      console.log("");
      console.log(`---RESPONSE---`);

      if (getResponse.message === "SUCCESS" && getResponse.success === true) {
        setApiResponse(getResponse.data);
        setIsBackDropOpen(false);

        console.log(getResponse.data.sessionUrl);
        // window.open(getResponse.data.sessionUrl, "_blank");

        // setIframURL(getResponse.data.sessionUrl);
        window.location.href = getResponse.data.sessionUrl;

        await signOut(auth);
      } else {
        navigate("/pricing");
        setIsBackDropOpen(false);
        setIsSnackOpen(true);
        setErrorMessage(getResponse.message);
      }
    } catch (errorFromPricing) {
      navigate("/pricing");
      setIsBackDropOpen(false);
      setIsSnackOpen(true);

      console.log(`Error from pricing`, errorFromPricing);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsSnackOpen(false);
  };

  return (
    <>
      <iframe
        title="price"
        allow="payment; fullscreen"
        sandbox="allow-scripts allow-same-origin"
        src={iframeURL}
        width="400"
        height="400"
        style={{ border: "none" }}
      />

      <Snackbar
        open={isSnackOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error" variant="filled">
          {errorMessage}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={isBackDropOpen}
        onClick={handleClose}
      >
        <CircularProgress />
      </Backdrop>
      <div className="product-container">
        <div className="product-image-container">
          <img src={logo} alt="logo" className="product-logo" />
        </div>

        <div className="product-title-container">
          <h1 className="product-title">Choose Our Subscription Plans</h1>
        </div>

        {/* ------------------------------ */}
        <div className="product-pricing-container">
          {/* Personal Plans */}

          {personalPlans?.length > 0 && (
            <div className="product-pricing-section">
              <h3 className="product-pricing-section-title">Home plans</h3>
              <div className="product-pricing-toggle-container">
                <span
                  className={
                    personalBillingPeriod === "Monthly" ? "active" : ""
                  }
                >
                  Monthly
                </span>

                <ToggleButton
                  isActive={personalBillingPeriod === "Yearly"}
                  onToggle={() =>
                    setPersonalBillingPeriod(
                      personalBillingPeriod === "Monthly" ? "Yearly" : "Monthly"
                    )
                  }
                />

                <span
                  className={personalBillingPeriod === "Yearly" ? "active" : ""}
                >
                  Yearly
                </span>
              </div>
              <div className="product-card-container">
                {personalPlans?.map((car, index) => (
                  <div className="product-card" key={index}>
                    <p className="product-card-title">{car.name}</p>
                    <h3>
                      {/* ${car.price} */}${(car.price / 100).toFixed(2)}
                      <span className="monthly-yearly">
                        {`/ ${personalBillingPeriod.toLowerCase()}`}
                      </span>
                    </h3>

                    <button onClick={() => handleSubscribe(car)}>
                      Subscribe
                    </button>

                    <ul>
                      {car.details.map((detail, i) => (
                        <li key={i}>{detail}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Business Plans */}

          {businessPlans?.length > 0 && (
            <div className="product-section-business">
              <h3 className="product-section-title-business">Business Plans</h3>
              <div className="product-pricing-toggle-container">
                <span
                  className={
                    businessBillingPeriod === "Monthly" ? "active" : ""
                  }
                >
                  Monthly
                </span>
                <ToggleButton
                  isActive={businessBillingPeriod === "Yearly"}
                  onToggle={() =>
                    setBusinessBillingPeriod(
                      businessBillingPeriod === "Monthly" ? "Yearly" : "Monthly"
                    )
                  }
                />

                <span
                  className={businessBillingPeriod === "Yearly" ? "active" : ""}
                >
                  Yearly
                </span>
              </div>
              <div className="product-card-container">
                {businessPlans?.map((car, index) => (
                  <div className="product-card" key={index}>
                    <p className="product-card-title">{car.name}</p>
                    <h3>
                      ${(car.price / 100).toFixed(2)}
                      <span className="monthly-yearly">{`/ user/ ${businessBillingPeriod.toLowerCase()}`}</span>
                    </h3>

                    <button onClick={() => handleSubscribe(car)}>
                      Subscribe
                    </button>

                    <ul>
                      {car.details.map((detail, i) => (
                        <li key={i}>{detail}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const ToggleButton = ({ isActive, onToggle }) => {
  return (
    <div
      className={`product-toggle-btn ${isActive ? "active" : ""}`}
      onClick={onToggle}
    >
      <div className="product-toggle-circle"></div>
    </div>
  );
};
