import { Navigate, Outlet, useLocation } from "react-router-dom";

export default function ProtectedRoute({ allowedCode }) {
  const location = useLocation();
  const state = location.state;

  if (!state || !state.userDetail || !state.accessToken) {
    return <Navigate to="/" />;
  }

  if (state.authCode !== allowedCode) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
}
