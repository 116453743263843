import { useState } from "react";
import "../../newComponent/HeaderComponents/PreferenceSettings.css";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import CloseIcon from "@mui/icons-material/Close";

import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";

import { PreferenceService } from "../../services/preferenceService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "5px",
  padding: 4,
  outline: "none", // Prevent focus outline

  maxHeight: "250px",
  overflowX: "auto",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function PreferenceSettings({
  isPreferenceSettingsOpen,
  setIsPreferenceSettingOpen,
  setIsDrawerOpen,
  storedLanguage,
  storedCountry,
  storedTimeZone,
  accessToken,
  fetchFilesData,
  listOfPreferenceData,
}) {
  const [selectedValue, setSelectedValue] = useState(storedLanguage || "en");
  const [selectedCountry, setSelectedCountry] = useState(storedCountry);
  const [selectedTimeZone, setSelectedTimeZone] = useState(storedTimeZone);
  const [preferenceLoading, setpreferenceLoading] = useState(false);

  const { t } = useTranslation();

  console.log(listOfPreferenceData?.data);

  const handleClose = () => {
    setIsPreferenceSettingOpen(false);
    setIsDrawerOpen((prev) => !prev);
  };

  const handleModalClose = () => {
    setIsPreferenceSettingOpen(false);
    setIsDrawerOpen((prev) => !prev);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;

    console.log(newValue);
    setSelectedValue(newValue);
  };

  const handleCountryChange = (event) => {
    const newValue = event.target.value;

    console.log(newValue);
    setSelectedCountry(newValue);
  };

  const handleTimeZoneChange = (event) => {
    const newValue = event.target.value;

    console.log(newValue);
    setSelectedTimeZone(newValue);
  };

  const handleSubmitPreference = async () => {
    setpreferenceLoading(true);

    const data = {
      prefLanguage: selectedValue,
      // prefTimezone: selectedTimeZone,
      // prefCountry: selectedCountry,
    };

    try {
      const response = await PreferenceService(data, accessToken);

      if (response.status === 200 && response.data.code === 0) {
        console.log(response);
        console.log(response.data);

        await fetchFilesData();

        setpreferenceLoading(false);
      }
    } catch (error) {
      setpreferenceLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <Modal open={isPreferenceSettingsOpen} onClose={handleClose}>
          <Fade in={isPreferenceSettingsOpen}>
            <Box sx={style}>
              {preferenceLoading && (
                <div className="spinner-container1 skip-global-styles">
                  <CircularProgress style={{ color: "blue" }} />
                </div>
              )}

              <IconButton onClick={handleClose} className="pre-icon-button">
                <CloseIcon className="pre-close-icon" />
              </IconButton>

              <h1 className="pre-heading">{t("Preference")}</h1>

              {/* Language Dropdown */}

              <div className="custom-row">
                <InputLabel className="custom-label">
                  {t("Language")}:
                </InputLabel>
                <FormControl className="custom-dropdown">
                  <Select
                    value={selectedValue}
                    onChange={handleChange}
                    className="custom-dropdown-input"
                    MenuProps={MenuProps}
                  >
                    {listOfPreferenceData?.data?.languageAvailableKV.map(
                      (lang) => (
                        <MenuItem
                          key={lang.key}
                          value={lang.key}
                          className="custom-dropdown-item"
                        >
                          {lang.display}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </div>

              {/* Country Dropdown */}
              {/* <div className="custom-row">
                <InputLabel className="custom-label">
                  {t("Country")}:
                </InputLabel>
                <FormControl className="custom-dropdown">
                  <Select
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    className="custom-dropdown-input"
                    MenuProps={MenuProps}
                  >
                    {listOfPreferenceData?.data?.countryAvailableKV.map(
                      (country) => (
                        <MenuItem
                          key={country.key}
                          value={country.key}
                          className="custom-dropdown-item"
                        >
                          {country.display}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </div> */}

              {/* Timezone Dropdown */}

              {/* <div className="custom-row">
                <InputLabel className="custom-label">
                  {t("Timezone")}:
                </InputLabel>
                <FormControl className="custom-dropdown">
                  <Select
                    value={selectedTimeZone}
                    onChange={handleTimeZoneChange}
                    className="custom-dropdown-input"
                    MenuProps={MenuProps}
                  >
                    {listOfPreferenceData?.data?.timezoneAvailableKV.map(
                      (timezone) => (
                        <MenuItem
                          key={timezone.key}
                          value={timezone.key}
                          className="custom-dropdown-item"
                        >
                          {timezone.display}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </div> */}

              <div className="pre-button-container">
                <div>
                  <Button
                    variant="contained"
                    disabled={preferenceLoading}
                    onClick={handleSubmitPreference}
                  >
                    {t("save")}
                  </Button>
                </div>
                <div>
                  <Button
                    className="pre-cancel-button"
                    variant="contained"
                    disabled={preferenceLoading}
                    onClick={handleModalClose}
                  >
                    {t("cancel")}
                  </Button>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    </>
  );
}

// import { useEffect, useState } from "react";
// import "../../newComponent/HeaderComponents/PreferenceSettings.css";

// import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";
// import Fade from "@mui/material/Fade";

// import CloseIcon from "@mui/icons-material/Close";

// import {
//   CircularProgress,
//   FormControl,
//   IconButton,
//   InputLabel,
//   MenuItem,
//   Select,
// } from "@mui/material";
// import { useTranslation } from "react-i18next";

// import Button from "@mui/material/Button";

// import { PreferenceService } from "../../services/preferenceService";
// import axios from "axios";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   borderRadius: "5px",
//   padding: 4,
//   outline: "none", // Prevent focus outline

//   maxHeight: "250px",
//   overflowX: "auto",
// };

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

// export default function PreferenceSettings({
//   isPreferenceSettingsOpen,
//   setIsPreferenceSettingOpen,
//   setIsDrawerOpen,
//   storedLanguage,
//   storedCountry,
//   storedTimeZone,
//   accessToken,
//   fetchFilesData,
//   listOfPreferenceData,
// }) {
//   const [selectedValue, setSelectedValue] = useState(storedLanguage);
//   const [selectedCountry, setSelectedCountry] = useState(storedCountry);
//   const [selectedTimeZone, setSelectedTimeZone] = useState(storedTimeZone);
//   const [preferenceLoading, setpreferenceLoading] = useState(false);

//   const { t } = useTranslation();

//   console.log(listOfPreferenceData?.data);

//   const handleClose = () => {
//     setIsPreferenceSettingOpen(false);
//     setIsDrawerOpen((prev) => !prev);
//   };

//   const handleModalClose = () => {
//     setIsPreferenceSettingOpen(false);
//     setIsDrawerOpen((prev) => !prev);
//   };

//   const handleChange = (event) => {
//     const newValue = event.target.value;

//     console.log(newValue);
//     setSelectedValue(newValue);
//   };

//   const handleCountryChange = (event) => {
//     const newValue = event.target.value;

//     console.log(newValue);
//     setSelectedCountry(newValue);
//   };

//   const handleTimeZoneChange = (event) => {
//     const newValue = event.target.value;

//     console.log(newValue);
//     setSelectedTimeZone(newValue);
//   };

//   const handleSubmitPreference = async () => {
//     setpreferenceLoading(true);

//     const data = {
//       prefLanguage: selectedValue,
//       prefTimezone: selectedTimeZone,
//       prefCountry: selectedCountry,
//     };

//     try {
//       const response = await PreferenceService(data, accessToken);

//       if (response.status === 200 && response.data.code === 0) {
//         console.log(response);
//         console.log(response.data);

//         await fetchFilesData();

//         setpreferenceLoading(false);
//       }
//     } catch (error) {
//       setpreferenceLoading(false);
//       console.log(error);
//     }
//   };

//   return (
//     <>
//       <div>
//         <Modal open={isPreferenceSettingsOpen} onClose={handleClose}>
//           <Fade in={isPreferenceSettingsOpen}>
//             <Box sx={style}>
//               {preferenceLoading && (
//                 <div className="spinner-container1 skip-global-styles">
//                   <CircularProgress style={{ color: "blue" }} />
//                 </div>
//               )}

//               <IconButton onClick={handleClose} className="pre-icon-button">
//                 <CloseIcon className="pre-close-icon" />
//               </IconButton>

//               <h1 className="pre-heading">{t("Preference")}</h1>

//               {/* Language Dropdown */}

//               <div className="custom-row">
//                 <InputLabel className="custom-label">
//                   {t("Language")}:
//                 </InputLabel>
//                 <FormControl className="custom-dropdown">
//                   <Select
//                     value={selectedValue}
//                     onChange={handleChange}
//                     className="custom-dropdown-input"
//                   >
//                     {listOfPreferenceData?.data?.languageAvailableKV.map(
//                       (lang) => (
//                         <MenuItem
//                           key={lang.key}
//                           value={lang.key}
//                           className="custom-dropdown-item"
//                         >
//                           {lang.display}
//                         </MenuItem>
//                       )
//                     )}
//                   </Select>
//                 </FormControl>
//               </div>

//               {/* Country Dropdown */}
//               <div className="custom-row">
//                 <InputLabel className="custom-label">
//                   {t("Country")}:
//                 </InputLabel>
//                 <FormControl className="custom-dropdown">
//                   <Select
//                     value={selectedCountry}
//                     onChange={handleCountryChange}
//                     className="custom-dropdown-input"
//                   >
//                     {listOfPreferenceData?.data?.countryAvailableKV.map(
//                       (country) => (
//                         <MenuItem
//                           key={country.key}
//                           value={country.key}
//                           className="custom-dropdown-item"
//                         >
//                           {country.display}
//                         </MenuItem>
//                       )
//                     )}
//                   </Select>
//                 </FormControl>
//               </div>

//               {/* Timezone Dropdown */}

//               <div className="custom-row">
//                 <InputLabel className="custom-label">
//                   {t("Timezone")}:
//                 </InputLabel>
//                 <FormControl className="custom-dropdown">
//                   <Select
//                     value={selectedTimeZone}
//                     onChange={handleTimeZoneChange}
//                     className="custom-dropdown-input"
//                     MenuProps={MenuProps}
//                   >
//                     {listOfPreferenceData?.data?.timezoneAvailableKV.map(
//                       (timezone) => (
//                         <MenuItem
//                           key={timezone.key}
//                           value={timezone.key}
//                           className="custom-dropdown-item"
//                         >
//                           {timezone.display}
//                         </MenuItem>
//                       )
//                     )}
//                   </Select>
//                 </FormControl>
//               </div>

//               <div className="pre-button-container">
//                 <div>
//                   <Button
//                     variant="contained"
//                     disabled={preferenceLoading}
//                     onClick={handleSubmitPreference}
//                   >
//                     {t("save")}
//                   </Button>
//                 </div>
//                 <div>
//                   <Button
//                     className="pre-cancel-button"
//                     variant="contained"
//                     disabled={preferenceLoading}
//                     onClick={handleModalClose}
//                   >
//                     {t("cancel")}
//                   </Button>
//                 </div>
//               </div>
//             </Box>
//           </Fade>
//         </Modal>
//       </div>
//     </>
//   );
// }

// -------------------------
// {
//   <div className="custom-row">
//                 <InputLabel className="custom-label">
//                   MyCar
//                 </InputLabel>
//                 <FormControl className="custom-dropdown">
//                   <Select

//                   >
//                     <MenuItem
//                       value="Audi"

//                     >
//                       Audi
//                     </MenuItem>
//                     <MenuItem
//                       value="Ertika"

//                     >
//                       Ertika
//                     </MenuItem>
//                   </Select>
//                 </FormControl>
//               </div>
// }

// "carData": {
//         "car1": [
//             {
//                 "carkey": "audi",
//                 "cardisplay": "Audi"
//             },
//             {
//                 "carkey": "benz",
//                 "cardisplay": "MayBach"
//             },
//             {
//                 "carkey": "aston",
//                 "cardisplay": "AstonMartin"
//             }
//         ],
//       }
