import "../../newComponent/HeaderComponents/ProfileSection.css";

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import Axios from "axios";
import { useSelector } from "react-redux";

import appLogo from "../../assets/alterprofile.png";
import BusinessProfile from "../../components/Business_Settings/BusinessProfile";
import UserManagementOverlay from "./UserManagementOverlay";

import {
  CloudOutlined,
  InsertInvitationOutlined,
  PermIdentityOutlined,
  LogoutOutlined,
  PrivacyTipOutlined,
  GroupsOutlined,
  HelpOutlineOutlined,
  ManageAccountsOutlined,
  SettingsOutlined,
} from "@mui/icons-material";

import { Alert, Badge, Snackbar } from "@mui/material";
import PreferenceSettings from "./PreferenceSettings";

const GET_USER_STORAGE = process.env.REACT_APP_GET_USER_STORAGE_API_URL;

const GET_INVITATION_URL = process.env.REACT_APP_MY_INVITATION_URL;

const ACCEPT_INVITATION_HANDELER = process.env.REACT_APP_ACCEPT_INVITATION_URL;

export default function ProfileSection({
  name,
  onLogout,
  email,
  photoURL,
  isDrawerOpen,
  setIsDrawerOpen,
  accessToken,
  fetchFilesData,
  listOfPreferenceData,
}) {
  const { t } = useTranslation();
  const roles = useSelector((state) => state.user.roles);
  const isBusiness = useSelector((state) => state.user.isBusiness);

  const [isBusinessSettingsOpen, setIsBusinessSettingsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreferenceSettingsOpen, setIsPreferenceSettingOpen] =
    useState(false);
  const [invitation, setInvitation] = useState();
  const [isViewInvitation, setIsViewInvitation] = useState(false);
  const [storageData, setStorageData] = useState({
    maxAllowedCapacity: 0.0,

    actualSize: 0.0,
  });

  const [acceptInvitationResponse, setAcceptInvitationResponse] = useState();

  const [open, setOpen] = useState(false);

  const storedLanguage = useSelector((state) => state.user.prefLanguage);
  const storedCountry = useSelector((state) => state.user.prefCountry);
  const storedTimeZone = useSelector((state) => state.user.prefTimezone);

  const headers = {
    "Cache-Control": "no-cache",
    Authorization: `Bearer ${accessToken}`,
  };

  useEffect(() => {
    const fetchStorage = async () => {
      try {
        const storageResponse = await Axios.get(GET_USER_STORAGE, { headers });
        const storageData = await storageResponse.data;

        setStorageData(storageData);
        // console.log(storageData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchStorage();
  }, []);

  useEffect(() => {
    fetchInvitation();
  }, []);

  const fetchInvitation = async () => {
    try {
      const invitationResponse = await Axios.get(GET_INVITATION_URL, {
        headers,
      });

      const invitationData = invitationResponse.data;

      console.log(invitationResponse.data);

      setInvitation(invitationData);
    } catch (error) {
      console.log(`INVITATION ERROR:`, error);
    }
  };

  const totalStorage = storageData.maxAllowedCapacity;
  const usedStorage = storageData.actualSize;
  // const totalStorage = storageData.maxAllowedCapacity;

  const percentageUsed = (usedStorage / totalStorage) * 100;

  const BusinessSettingsHandler = () => {
    setIsBusinessSettingsOpen(true);
    setIsDrawerOpen((prev) => !prev);
  };

  const CloseBusinessSettingsHandler = () => {
    setIsBusinessSettingsOpen(false);
    setIsDrawerOpen((prev) => !prev);
  };

  const openModal = () => {
    setIsModalOpen(true);

    setIsDrawerOpen((prev) => !prev);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsDrawerOpen((prev) => !prev);
  };

  const openPreferenceSettings = () => {
    console.log(`Clicked`);
    setIsPreferenceSettingOpen(true);
    setIsDrawerOpen((prev) => !prev);
  };

  const closePreferenceSettings = () => {};

  const invitationModalClickHandler = () => {
    setIsViewInvitation(true);
  };

  const cancelInvitationHandler = () => {
    setIsViewInvitation(false);
  };

  const acceptInvitationHandeler = async () => {
    try {
      const response = await Axios.post(
        ACCEPT_INVITATION_HANDELER,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Success", response);
        setAcceptInvitationResponse(response.data);
        setOpen(true);
        setIsViewInvitation(false);
        fetchInvitation();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <div className={`custom-drawer ${isDrawerOpen ? "open" : ""}`}>
        <div className="drawer-content1">
          <img
            src={photoURL || appLogo}
            alt="UserImage"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = appLogo;
            }}
          />

          <div className="name-div1">
            <p>
              {t("Hi")} {name}
            </p>
            <span>{email}</span>
          </div>
        </div>

        <div className="drawer-options1">
          {invitation?.data.length > 0 && (
            <div
              className="invitation-btn"
              onClick={invitationModalClickHandler}
            >
              <Badge
                color="secondary"
                variant="dot"
                invisible={false}
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: "#FF5B61",
                  },
                }}
              >
                <InsertInvitationOutlined />
              </Badge>

              <button>{t("ViewInvitation")}</button>
            </div>
          )}

          {/* ------------------------------------ */}

          {isBusiness && (
            <div className="drawer-option1" onClick={BusinessSettingsHandler}>
              <PermIdentityOutlined />
              <span>{t("BusinessSettings")}</span>
            </div>
          )}
          {roles &&
            roles.includes("ADMIN") && ( // Conditionally render User Management row
              <div className="drawer-option1" onClick={openModal}>
                <GroupsOutlined />
                <span>{t("UserManagement")}</span>
              </div>
            )}

          <div className="drawer-option1">
            <ManageAccountsOutlined />
            <span>{t("ManageAccounts")}</span>
          </div>

          <div className="drawer-option1" onClick={openPreferenceSettings}>
            <SettingsOutlined />
            <span>{t("Preference")}</span>
          </div>

          <div className="drawer-option1">
            <PrivacyTipOutlined />
            <span>{t("Privacy&Security")}</span>
          </div>

          <div className="drawer-option1">
            <HelpOutlineOutlined />
            <span>{t("Help")}</span>
          </div>

          <div className="logout-btn1" onClick={onLogout}>
            <LogoutOutlined />
            <button>{t("Logout")}</button>
          </div>

          {/* --------------------------------------------- */}

          <div id="settingStorageOverview" className="storage-container">
            <div className="storage-text">
              <CloudOutlined className="cloud-icon" />
              {t("StorageOverview")}
            </div>
            <div className="storage-bar">
              <div
                className="storage-bar-fill"
                style={{
                  width: `${percentageUsed}%`,
                }}
              ></div>
            </div>
            <div className="storage-info">
              {usedStorage.toFixed(2)} GB {t("of")} {totalStorage} GB{" "}
              {t("used")}
            </div>
            <button
              className="storage-upgrade-btn"
              onMouseEnter={(e) => {
                e.target.classList.add("hover");
              }}
              onMouseLeave={(e) => {
                e.target.classList.remove("hover");
              }}
            >
              {t("GetMoreStorage")}
            </button>
          </div>

          {/* --------------------------------------------- */}
        </div>
      </div>

      {isBusinessSettingsOpen && (
        <BusinessProfile
          CloseBusinessSettingsHandler={CloseBusinessSettingsHandler}
        />
      )}

      <UserManagementOverlay
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        accessToken={accessToken}
      />

      {isViewInvitation && (
        <div className="success-confirm-overlay">
          <div
            className="delete-confirm-container"
            onClick={(e) => e.stopPropagation()}
          >
            <h3>{`${invitation?.data[0]?.createdBy} invited you to to join.`}</h3>
            <button className="btn-yes" onClick={acceptInvitationHandeler}>
              Accept
            </button>
            <button className="btn-no" onClick={cancelInvitationHandler}>
              Cancel
            </button>
          </div>
        </div>
      )}

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {acceptInvitationResponse?.data}
          {/* Success */}
        </Alert>
      </Snackbar>

      {isPreferenceSettingsOpen && (
        <PreferenceSettings
          isPreferenceSettingsOpen={isPreferenceSettingsOpen}
          setIsPreferenceSettingOpen={setIsPreferenceSettingOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          storedLanguage={storedLanguage}
          storedCountry={storedCountry}
          storedTimeZone={storedTimeZone}
          accessToken={accessToken}
          fetchFilesData={fetchFilesData}
          listOfPreferenceData={listOfPreferenceData}
        />
      )}
    </>
  );
}
