import Axios from "axios";

const GET_PRODUCTS_URL =
  "https://devapi.ez-byte.com:5050/groupadmin/api/v1/product";

export default async function GetProductService(accessToken) {
  const headers = {
    "Cache-Control": "no-cache",
    Authorization: `Bearer ${accessToken}`,
  };

  try {
    const response = await Axios.get(GET_PRODUCTS_URL, { headers });

    return response.data;
  } catch (error) {
    console.log(`Error from getproduct service:`, error);
  }
}
