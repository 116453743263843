import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { auth } from "./config/firebase";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import HomeScreen from "./screens/HomeScreen";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RingLoader } from "react-spinners";
import LandingScreen from "./components/LandingPage/LandingScreen";
import CheckoutPage from "./components/LandingPage/CheckoutScreen";

import ProtectedRoute from "../src/utils/protectedRoute";
import ProductPricing from "./newComponent/products/ProductPricing";

export const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // TO MAINTAIN UPON SCREEN REFRESH
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user); // User is authenticated
      } else {
        setUser(null); // No user, so not authenticated
      }
      setLoading(false); // Finished loading auth state
    });

    return () => unsubscribe(); // Clean up subscription on unmount
  }, []);

  if (loading) {
    return (
      <div className="spinner-container">
        <RingLoader color="#3498db" />
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingScreen />} />

        <Route path="/login" element={<LoginScreen />} />

        <Route path="/register" element={<RegisterScreen />} />

        <Route path="/checkout" element={<CheckoutPage />} />

        <Route
          path="/pricing"
          element={user ? <ProductPricing /> : <Navigate to="/" />}
        />

        <Route element={<ProtectedRoute allowedCode={0} />}>
          <Route
            path="/home"
            element={user ? <HomeScreen /> : <Navigate to="/" />}
          />
        </Route>
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
